import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { CustomCol, ImgWrapper, Text } from "./home-cards.css";
import { Row } from "reactstrap";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { MasterDataContext } from "../../store/masterDataContext";

// const buildConditionQueryParam = (internalId) => {
//   const condition = conditionsData.find(c => c.InternalValue === internalId);
//   let queryParam = internalId;
//
//   if (condition && condition.Children) {
//     const childConditions = condition.Children.map(child => child.InternalValue).join('~');
//     queryParam += `~${childConditions}`;
//   }
//
//   return queryParam;
// };
const HomeCards = ({ cards }) => {
  const masterData = useContext(MasterDataContext);
  useEffect(() => {
    // console.log(cards[0]);
    if (masterData && masterData.masterData && masterData.masterData.CONDITION) {
      // console.log(masterData.masterData.CONDITION[0]);
    }

  }, [masterData.masterData]);

  const generateLinkUrl = (internalId, redirect) => {
    if (!masterData || !masterData.masterData || !masterData.masterData.CONDITION) {
      // fallback to legacy hardcoded redirect from homepage.json
      return redirect;
    }
    const condition = masterData.masterData.CONDITION.find(c => c.InternalValue === internalId);
    let queryParam = internalId;
    if (condition && condition.Children) {
      const childConditions = condition.Children.map(child => child.InternalValue).join('~');
      queryParam += `~${childConditions}`;
    }
    // generates the studies query dynamically based on the condition selected
    return `/studies?Keyword=&gender=&ageRange=&Status=RECSTATUS_RECRUITING~RECSTATUS_NOT_YET_RECRUITING&Latitude=&Longitude=&MileRadius=&page=0&SortField=Location_Distance&SortOrder=asc&conditions=${queryParam}`;
  };
  return (
    <Row>
      {cards.map((card, index) => (
        <CustomCol key={index} xs={6} md={4}>
          <Link to={generateLinkUrl(card.internalId, card.redirect)}>
            <ImgWrapper>
              <Img
                draggable={false}
                fluid={card.image.childImageSharp.fluid}
                alt={card.altText}
                className={card.id}
              />
            </ImgWrapper>
            <Text>{card.description}</Text>
          </Link>
        </CustomCol>
      ))}
    </Row>
  );
};

HomeCards.propTypes = {
  cards: PropTypes.array
};

export default HomeCards;
